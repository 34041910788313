import { Box, Container, Flex, Heading, Image, Text } from "@chakra-ui/react";
import arrows from "../../../assets/svg/arrows.svg";
import mobileArrows from "../../../assets/svg/mobileArrows.svg";
import card1 from "../../../assets/svg/card1.svg";
import card2 from "../../../assets/svg/card2.svg";
import card3 from "../../../assets/svg/card3.svg";
import card4 from "../../../assets/svg/card4.svg";
import card5 from "../../../assets/svg/card5.svg";

const Roadmap = () => {
	const position = (image, style) => {
		return {
			content: "''",
			position: "absolute",
			bgImage: image,
			bgRepeat: "no-repeat",
			bgPosition: "50%",
			bottom: "100%",
			left: "0",
			width: "100%",
			height: "100%",
			...style,
		};
	};

	return (
		<>
			<Box w="100vw" position={"relative"}>
				<Container maxW="100%" display={["none", null, "block"]}>
					<Heading
						fontWeight={"900"}
						textAlign="center"
						fontSize={["3xl", null, "4xl", "5xl", "6xl"]}
					>
						HOW PYME WORKS
					</Heading>

					<Box minH={"585px"} position={"relative"}>
						<Flex justifyContent={"center"}>
							<Box
								w="730px"
								h={"100%"}
								_before={{
									content: '""',
									bgImage: arrows,
									bgRepeat: "no-repeat",
									position: "absolute",
									top: "0",
									left: "0",
									height: "100%",
									width: "100%",
									backgroundPosition: "calc(50% + 60px)",
								}}
							/>
						</Flex>

						<Flex justifyContent={"center"} flexWrap="wrap">
							<Flex w="50%" flexDirection={"column"} alignItems="flex-end">
								<Text
									position={"relative"}
									_before={position(card1)}
									fontWeight="500"
									mt="4rem"
									mr="3rem"
									display={"inline-block"}
								>
									List a project you need completing
									<br /> in web3 on the Pyme marketplace
								</Text>
								<Text
									position={"relative"}
									_before={position(card2)}
									fontWeight="500"
									mt="11.4rem"
									textAlign={"center"}
									mr="9rem"
								>
									Work gets completed with <br />
									both sides verifying
								</Text>
							</Flex>

							<Flex
								flexDirection={"column"}
								alignItems="flex-start"
								float={"right"}
								w="50%"
							>
								<Text
									position={"relative"}
									_before={position(card3)}
									fontWeight="500"
									mt="9rem"
									display={"inline-block"}
									textAlign="center"
								>
									Applicants apply using previous
									<br />
									work history backed by NFTs
								</Text>
								<Text
									position={"relative"}
									_before={position(card4)}
									fontWeight="500"
									mt="12rem"
									textAlign={"center"}
									ml="4rem"
								>
									Worker gets paid instantly in Matic
									<br />
									(More options coming soon)
								</Text>
							</Flex>
							<Text
								position={"relative"}
								_before={position(card5)}
								fontWeight="500"
								textAlign={"center"}
								mt="6rem"
								transform={"translateY(20px)"}
							>
								Unique NFT awarded to worker
								<br />
								who grows their Pyme profile
							</Text>
						</Flex>
					</Box>
				</Container>

				<Container maxW={"100%"} display={["block", null, "none"]}>
					<Heading fontWeight={"900"} textAlign="center">
						HOW PYME WORKS
					</Heading>

					<Box minH={"585px"}>
						<Flex
							_before={{
								content: '""',
								bgImage: mobileArrows,
								bgRepeat: "no-repeat",
								position: "absolute",
								top: "0",
								left: "0",
								height: "100%",
								width: "100%",
								backgroundSize: "632px",
								backgroundPosition: "50%",
							}}
						>
							<Flex
								w="53%"
								flexDirection={"column"}
								alignItems="flex-end"
								fontSize={["0.65rem", "0.75rem"]}
								whiteSpace={"nowrap"}
							>
								<Box
									position={"relative"}
									sx={{
										"@media (max-width: 368px)": {
											left: "16px",
										},
									}}
								>
									<Text
										mt={["4.5rem", "5rem"]}
										position={"relative"}
										_before={position(card1)}
										fontWeight="500"
										display={"inline-block"}
										sx={{
											"@media (max-width: 326px)": {
												marginTop: "3.5rem",
											},
										}}
									>
										List a project you need completing
										<br /> in web3 on the Pyme marketplace
									</Text>
								</Box>

								<Box
									position={"relative"}
									sx={{
										"@media (max-width: 326px)": {
											left: "8px",
										},
									}}
								>
									<Text
										position={"relative"}
										mt={["10rem"]}
										mr={"2rem"}
										_before={position(card2, {
											width: ["75px", "90px"],
											height: ["75px", "90px"],
											bottom: "13px",
											left: "30px",
										})}
										fontWeight="500"
										textAlign={"center"}
									>
										Work gets completed with <br />
										both sides verifying
									</Text>
								</Box>
							</Flex>

							<Flex
								w="47%"
								float={"right"}
								flexDirection="column"
								alignItems="flex-start"
								fontSize={["0.65rem", "0.75rem"]}
								whiteSpace={"nowrap"}
							>
								<Box position={"relative"} left={["-40px", "-55px"]}>
									<Text
										position={"relative"}
										mt={"11rem"}
										_before={position(card3, {
											width: "70px",
											height: "70px",
											left: "50%",
											transform: "translate(-50%)",
										})}
										fontWeight="500"
										textAlign="center"
										sx={{
											"@media (max-width: 326px)": {
												marginTop: "10rem",
											},
										}}
									>
										Applicants apply using previous
										<br />
										work history backed by NFTs
									</Text>
								</Box>
								<Box position={"relative"} left="-68px">
									<Text
										position={"relative"}
										whiteSpace={"nowrap"}
										_before={position(card4, {
											width: "65px",
											height: "65px",
											left: "50%",
											transform: "translate(-50%)",
										})}
										fontWeight="500"
										textAlign={"center"}
										mt={["9rem", "8rem"]}
									>
										Worker gets paid instantly in Matic
										<br />
										(More options coming soon)
									</Text>
								</Box>

								<Box position={"relative"} left="-105px">
									<Text
										position={"relative"}
										_before={position(card5, {
											width: "70px",
											height: "70px",
											left: "50%",
											transform: "translate(-50%)",
										})}
										fontWeight="500"
										textAlign={"center"}
										mt={["5.3rem", "6rem"]}
									>
										Unique NFT awarded to worker
										<br />
										who grows their Pyme profile
									</Text>
								</Box>
							</Flex>
						</Flex>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default Roadmap;
