// import Banner from "../Banner/Banner";
// import Waitlist from "../Waitlist/Waitlist";
import { Box, Center } from "@chakra-ui/react";
import HomeBanner from "./HomeBanner/HomeBanner";
import Jobs from "./Jobs/Jobs";
import HireSection from "./Hire Section/HireSection";
import WorkSection from "./Work Section/WorkSection";
import Roadmap from "./Roadmap/Roadmap";
import BetaAccess from "./Beta Access/BetaAccess";
import Footer from "./Footer/Footer";

const Home = () => {
	// return (
	// 	<>
	// 		<Banner />
	// 		{/* <Card /> */}
	// 		{/* <BottomBanner /> */}
	// 		{/* <BlogPosts /> */}
	// 		<Waitlist />
	// 	</>
	// );
	return (
		<>
			<HomeBanner />
			<Box mt={8} as="section">
				<Jobs />
			</Box>
			<Box mt={8} as="section">
				<HireSection />
			</Box>
			<Box mt={8} as="section">
				<WorkSection />
			</Box>
			<Box mt={8} as="section">
				<Roadmap />
			</Box>
			<Box as="section">
				<BetaAccess />
			</Box>
		</>
	);
};

export default Home;
