import "./App.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Home from "./components/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Blog from "./components/Blog/Blog";
import Layout from "./components/Layout/Layout";
import Error404 from "./components/Error/Error404";
import { Center } from "@chakra-ui/react";
import IndividualBlog from "./components/Blog/Individual Blog/IndividualBlog";

function App() {
	const theme = extendTheme({
		components: {
			Heading: {
				baseStyle: {
					fontFamily: "Poppins",
					fontWeight: "700",
				},
			},
			Text: {
				baseStyle: {
					fontFamily: "Poppins",
				},
			},
			Button: {
				baseStyle: {
					fontFamily: "Poppins",
					fontWeight: "400",
				},
			},
		},
	});
	return (
		<div className="App">
			<ChakraProvider theme={theme}>
				<BrowserRouter>
					<Layout>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route
								path="*"
								element={
									<Center
										minH={[
											"calc(100vh - (58px + 88px))",
											"calc(100vh - (58px + 88px))",
										]}
									>
										<Error404 />
									</Center>
								}
							/>
						</Routes>
					</Layout>
				</BrowserRouter>
			</ChakraProvider>
		</div>
	);
}

export default App;
