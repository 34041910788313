const TimeIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
		<path
			d="M7 0a7 7 0 1 0 7 7 7 7 0 0 0-7-7Zm3.15 8.4H7.006a.9.9 0 0 1-.875-.875V3.509a.875.875 0 0 1 1.75 0v3.137h2.269a.875.875 0 0 1 0 1.75Z"
			style={{
				fill: "inherit",
				fillRule: "evenodd",
			}}
		/>
	</svg>
);

export default TimeIcon;
