import {
	background,
	Box,
	Center,
	Container,
	Flex,
	Heading,
	Image,
	Text,
	VStack,
} from "@chakra-ui/react";
import arrow2 from "../../../assets/svg/arrow2.svg";
import money from "../../../assets/img/money-emoji.png";
import trophy from "../../../assets/img/trophy-emoji.png";
import spark from "../../../assets/img/collision-emoji.png";
import check from "../../../assets/img/check-emoji.png";
import banner from "../../../assets/svg/sectionSvg2.svg";

const WorkSection = () => {
	const work = [
		{ title: "Get paid instantly.", image: money },
		{ title: "Rewarded with a unique NFT", image: trophy },
		{ title: "Build a profile of completed work", image: check },
		{ title: "Access to the best jobs in web3", image: spark },
	];
	return (
		<>
			<Box position={"relative"}>
				<Container
					maxW={["100%", null, "container.sm"]}
					position="relative"
					display={["block", null, "none"]}
				>
					<Box position={"absolute"} left="50%" transform={"translateX(-50%)"}>
						<Heading
							fontWeight={"900"}
							position={"relative"}
							_after={{
								content: `url(${arrow2})`,
								position: "absolute",
								width: "200px",
								height: "200px",
								left: "75px",
								top: "-49px",
							}}
							whiteSpace="nowrap"
						>
							WORK IN WEB3
						</Heading>
					</Box>
					<Center>
						<VStack align={"stretch"} pt="5rem">
							{work.map((el, i) => {
								return (
									<Flex alignItems={"center"} key={i} py={1}>
										<Image src={el.image} w="25px" h="25px" />
										<Text fontWeight={"500"} fontSize="sm" ml="1rem">
											{el.title}
										</Text>
									</Flex>
								);
							})}
						</VStack>
					</Center>
				</Container>

				<Container
					maxW={["100%", null, null, null, "container.xl"]}
					display={["none", null, "block"]}
					position={"relative"}
				>
					<Flex flexDirection={"row"} position="relative" mr="3rem">
						<Box
							flex={1}
							position="relative"
							_before={{
								content: "''",
								position: "absolute",
								width: "100%",
								height: "100%",
								top: "0",
								left: "0",
								bgImage: banner,
								bgRepeat: "no-repeat",
								bgPosition: ["50% -20px", null, null, "50% -35px", "50% -69px"],
								backgroundSize: ["300px", null, null, "400px", "500px"],
							}}
						/>
						<Box>
							<Flex justifyContent={"flex-end"}>
								<Heading
									fontWeight={"900"}
									fontSize={["3xl", null, "4xl", "5xl", "6xl"]}
								>
									WORK IN WEB3
								</Heading>
								<Image
									src={arrow2}
									position="absolute"
									w="220px"
									top="-3.6rem"
									right="-3.3rem"
								/>
							</Flex>
							<VStack align={"stretch"} mr="5rem" pt="1.3rem">
								{work.map((el, i) => {
									return (
										<Flex alignItems={"center"} key={i} py={1}>
											<Image
												src={el.image}
												w={["25px", null, null, "38px", "45px"]}
												h={["25px", null, null, "38px", "45px"]}
											/>
											<Text
												fontWeight={"500"}
												fontSize={["sm", null, null, "xl", "2xl"]}
												ml="1rem"
											>
												{el.title}
											</Text>
										</Flex>
									);
								})}
							</VStack>
						</Box>
					</Flex>
				</Container>
			</Box>
		</>
	);
};

export default WorkSection;
