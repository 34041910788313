const MailIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
		<path
			d="M14 2.941a1.3 1.3 0 0 0-1.3-1.3H1.3a1.3 1.3 0 0 0-1.3 1.3v8.118a1.3 1.3 0 0 0 1.3 1.3h11.4a1.3 1.3 0 0 0 1.3-1.3ZM6.636 8.084 2.7 4.337a.451.451 0 0 1 .349-.771h7.905a.45.45 0 0 1 .347.772L7.332 8.085a.514.514 0 0 1-.696-.001Z"
			style={{
				fill: "inherit",
				fillRule: "evenodd",
			}}
		/>
	</svg>
);

export default MailIcon;
