import { Box } from "@chakra-ui/react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Layout = (props) => {
	return (
		<>
			<Header />
			<Box as={"main"} mt={"56px"} overflow="hidden">
				{props.children}
			</Box>
			<Footer />
		</>
	);
};

export default Layout;
