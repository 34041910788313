import {
	Avatar,
	Box,
	Center,
	Flex,
	Heading,
	Image,
	Link,
	Text,
	VStack,
} from "@chakra-ui/react";
import avatar1 from "../../../../assets/img/avatar1.png";
import avatar2 from "../../../../assets/img/avatar2.png";
import avatar3 from "../../../../assets/img/avatar3.png";
import eyesEmoji from "../../../../assets/img/eyes-emoji.png";

const Card = ({ data, cardHeading, image }) => {
	const randomImage = () => {
		const number = Math.floor(Math.random() * (4 - 1) + 1);
		switch (number) {
			case 1:
				return avatar1;
			case 2:
				return avatar2;
			case 3:
				return avatar3;
		}
	};
	return (
		<>
			<Box
				w="100%"
				boxShadow="rgba(158, 25, 171, 0.3) 0px 1px 2px 0px, rgba(158, 25, 171, 0.3) 0px 1px 3px 1px;"
				position={"relative"}
			>
				<Box py={1}>
					<Flex
						position={"relative"}
						display={"table"}
						pl={["3rem", null, null, "3.6rem"]}
						pr="0.75rem"
					>
						<Image
							src={image}
							position="absolute"
							w={["40px", null, null, "50px"]}
							h={["40px", null, null, "50px"]}
							left={"7px"}
							top={["-11px", null, null, "-18px"]}
						/>
						<Heading
							fontWeight={"900"}
							fontSize={["1.2rem", null, null, "1.5rem"]}
							mt={"0.5rem"}
							lineHeight={["1.22", null, null, "1.1"]}
						>
							{cardHeading}
						</Heading>
					</Flex>
					<Box
						height="2px"
						bg="#9E19AB"
						ml="0.5rem"
						px={["5.9rem", null, null, "7.3rem"]}
						display="table"
					/>
					<Box
						height="2px"
						bg="#9E19AB"
						mt="2px"
						ml="0.5rem"
						display={"table"}
						px={["4.6rem", null, null, "5.9rem"]}
					/>
				</Box>
				<VStack align={"stretch"} filter="blur(6px)">
					{data.map((el, i) => {
						return (
							<Flex
								key={i}
								py={2.5}
								px={[2, null, null, 4]}
								alignItems={"center"}
								bg={(i + 1) % 2 != 0 ? "#f4e6f6" : null}
								mt="0 !important"
							>
								<Text fontWeight={"600"} fontSize={["xs", null, null, "md"]}>
									{i + 1}
								</Text>
								<Avatar
									ml={["0.3rem", null, null, "0.6rem"]}
									src={randomImage()}
									w={["8", null, null, "10"]}
									h={["8", null, null, "10"]}
								/>
								<Text
									fontWeight={"500"}
									fontSize={["0.7rem", "0.85rem", null, "0.95rem"]}
									ml={["0.3rem", null, null, "0.6rem"]}
									lineHeight={"17px"}
									flex={["0 1 auto", null, null, 1]}
								>
									{el.name}
								</Text>
								<Flex
									flex={[1, null, null, "none"]}
									alignItems="center"
									justifyContent={["flex-end", null, null, "center"]}
									minW={["none", null, null, "25%"]}
								>
									<Link
										fontSize={"0.75rem"}
										border="2px solid"
										backgroundClip={"padding-box"}
										px={1}
										fontWeight="500"
										style={{
											borderImageSlice: "1",
											borderImageSource:
												"linear-gradient(to right, #9E19AB, #7123B7)",
										}}
										whiteSpace="nowrap"
									>
										View More
									</Link>
								</Flex>
							</Flex>
						);
					})}
					<Center mt="0 !important" py={3}>
						<Link
							fontSize={["0.75rem", null, null, "0.9rem"]}
							border="2px solid"
							backgroundClip={"padding-box"}
							px={2}
							fontWeight="900"
							style={{
								borderImageSlice: "1",
								borderImageSource:
									"linear-gradient(to right, #9E19AB, #7123B7)",
							}}
							whiteSpace="nowrap"
						>
							VIEW ALL
						</Link>
					</Center>
				</VStack>
				<Flex
					alignItems={"center"}
					position="absolute"
					top="50%"
					left={"50%"}
					transform="translate(-50%, -50%)"
					w="100%"
					h="100%"
					justifyContent={"center"}
				>
					<Image src={eyesEmoji} w="35px" h="35px" />
					<Heading
						fontWeight={"900"}
						ml="0.5rem"
						bgGradient={"linear(to-r, #9E19AB, #7123B7)"}
						bgClip="text"
					>
						COMING SOON
					</Heading>
				</Flex>
			</Box>
		</>
	);
};

export default Card;
