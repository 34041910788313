import {
	Box,
	Button,
	Container,
	Flex,
	Heading,
	Image,
	Text,
	VStack,
} from "@chakra-ui/react";
import arrow1 from "../../../assets/svg/arrow1.svg";
import search from "../../../assets/img/search-emoji.png";
import polygon from "../../../assets/svg/polygon.svg";
import handshakeEmoji from "../../../assets/img/handshake-emoji.png";
import trophy from "../../../assets/img/trophy-emoji.png";
import sectionSvg from "../../../assets/svg/sectionSvg1.svg";

const HireSection = () => {
	const data = [
		{ title: "Find the best workes in web3", img: search },
		{ title: "Pay using MATIC", img: polygon },
		{ title: "See proof of work history", img: handshakeEmoji },
		{ title: "Award NFT's for completed work.", img: trophy },
	];

	return (
		<>
			<Box position={"relative"}>
				<Container
					maxW={["100%", null, null, null, "container.xl"]}
					position={"relative"}
				>
					<Flex
						flexDirection={["column", null, "row"]}
						ml={["0", null, "3rem"]}
					>
						<Box>
							<Heading
								fontWeight={"900"}
								ml={[4, null, "0px"]}
								fontSize={["3xl", null, "4xl", "5xl", "6xl"]}
							>
								HIRE IN WEB3
							</Heading>
							<Image
								src={arrow1}
								position="absolute"
								width={["200px", null, null, "270px", "280px"]}
								left={["-24px", null, "15px", "-28px", "-35px"]}
								top={[null, null, null, "62px", "108px"]}
							/>
							<VStack
								align={"stretch"}
								ml="126px"
								mt={"8px"}
								sx={{
									"@media (min-width: 372px)": {
										marginTop: "22px",
									},
									"@media (min-width: 401px)": {
										marginTop: "40px",
									},
									"@media (min-width: 427px)": {
										marginTop: "49px",
									},
								}}
							>
								{data.map((el, i) => {
									return (
										<Flex
											alignItems={"center"}
											key={i}
											py={[0.3, null, null, 1, 1.5]}
										>
											<Image
												src={el.img}
												w={["25px", null, null, "38px", "45px"]}
												h={["25px", null, null, "38px", "45px"]}
											/>
											<Text
												fontWeight={"500"}
												fontSize={["sm", null, null, "xl", "2xl"]}
												ml="1rem"
											>
												{el.title}
											</Text>
										</Flex>
									);
								})}
							</VStack>
						</Box>
						<Box
							display={["none", null, "block"]}
							flex={1}
							position="relative"
							_before={{
								content: "''",
								position: "absolute",
								width: "100%",
								height: "100%",
								top: "0",
								left: "0",
								bgImage: sectionSvg,
								bgRepeat: "no-repeat",
								bgSize: "cover",
								bgPosition: "50%",
								backgroundSize: ["300px", null, null, "400px", "500px"],
							}}
						/>
					</Flex>
				</Container>
			</Box>
		</>
	);
};

export default HireSection;
