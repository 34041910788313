import { Box, Flex, Image, Link } from "@chakra-ui/react";
import logo from "../../assets/img/pyme-logo-new-white.png";

const Footer = () => {
	return (
		<Flex
			height="110px"
			bgGradient={"linear(to-b, #9E19AB, #7123B7)"}
			alignItems="center"
			justifyContent={["center", null, "flex-start"]}
			flexDirection={["column-reverse", null, "row"]}
			px={3}
		>
			<Image src={logo} mt="6px" />
		</Flex>
	);
};

export default Footer;
