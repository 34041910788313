import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Text, Heading, Button } from "@chakra-ui/react";

function Error404() {
	return (
		<Box textAlign={"center"}>
			<Heading
				display="inline-block"
				as="h2"
				size="4xl"
				bgGradient="linear(to-r, #9E19AB, #7123B7)"
				backgroundClip="text"
			>
				Oops! A Dead End?
			</Heading>
			<br />
			<br />
			<br />
			<Text color={"gray.800"} mb={6}>
				The page you're looking for doesn't seem to exist. Should something be
				here and isn't? If so, please contact support.
			</Text>
			<br />
			<Button
				as={NavLink}
				to={"/"}
				bgGradient="linear(to-r, #9E19AB, #7123B7)"
				color="white"
				variant="solid"
				colorScheme={"pink"}
			>
				Go to Home
			</Button>
		</Box>
	);
}

export default Error404;
