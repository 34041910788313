import {
	Box,
	Button,
	Flex,
	Heading,
	Input,
	Text,
	useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import footerBackground from "../../../assets/svg/footerBackground.svg";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";

const BetaAccess = () => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const pattern =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const validateEmail = (email) => {
		if (pattern.test(email.toLowerCase())) {
			return true;
		} else {
			return false;
		}
	};

	const submit = async () => {
		if (validateEmail(email) && email.length) {
			try {
				setLoading(true);
				await addDoc(collection(db, "waitlist"), {
					email: email,
				}).then((res) => {
					setLoading(false);
					toast({
						description: "Email submitted.",
						isClosable: true,
						duration: 1000,
						render: () => (
							<Flex
								bg={"green.100"}
								p={3}
								fontFamily={"PRegular"}
								w={"fit-content"}
								gap={"0.3rem"}
								borderRadius={"5px"}
								margin={"0 auto"}
							>
								<Box as="span">✅</Box>
								<Box as={"span"} fontFamily="Poppins">
									Email submitted!
								</Box>
							</Flex>
						),
					});
				});
			} catch (err) {
				setLoading(false);
			}
		} else {
			toast({
				description: "Email submitted.",
				isClosable: true,
				duration: 1000,
				render: () => (
					<Flex
						bg={"red.100"}
						p={3}
						fontFamily={"PRegular"}
						w={"fit-content"}
						gap={"0.3rem"}
						borderRadius={"5px"}
						margin={"0 auto"}
					>
						<Box as="span">⛔</Box>
						<Box as={"span"} fontFamily="Poppins">
							Invalid Email.
						</Box>
					</Flex>
				),
			});
		}
	};
	return (
		<Box
			mt={["-1.7rem", "-1.2rem", "2.3rem"]}
			position="relative"
			_before={{
				content: "''",
				position: "absolute",
				top: "0",
				left: "0",
				width: "100%",
				height: "100%",
				backgroundColor: "#f5e9f7",
				bgImage: footerBackground,
				bgRepeat: "no-repeat",
				bgPosition: "center",
				bgSize: "cover",
				zIndex: "-1",
			}}
		>
			<Flex flexDirection={"column"} alignItems="center" minH={"53vh"}>
				<Flex
					textAlign={"center"}
					flex={1}
					flexDirection="column"
					alignItems="center"
					justifyContent={"center"}
				>
					<Heading fontWeight={"900"}>APPLY FOR BETA ACCESS</Heading>
					<Text
						textAlign={"center"}
						display="inline-block"
						fontWeight={"500"}
						mt={1}
						mb={4}
						fontSize={["sm", "md"]}
					>
						We are working hard to onboard more people to Pyme.
						<br />
						Apply now for first access.
					</Text>
					<Input
						type="email"
						size="lg"
						placeholder="Email Address"
						maxW={"400px"}
						borderColor="gray.400"
						border={"2px"}
						fontFamily="Poppins"
						_placeholder={{ color: "gray.500" }}
						display={["none", "block"]}
						onChange={(e) => setEmail(e.target.value)}
						bg="#f3f5ff"
					/>
					<Input
						type="email"
						size="md"
						placeholder="Email Address"
						maxW={"80%"}
						borderColor="gray.400"
						border={"2px"}
						fontFamily="Poppins"
						_placeholder={{ color: "gray.500" }}
						display={["block", "none"]}
						onChange={(e) => setEmail(e.target.value)}
						bg="#f3f5ff"
					/>
					<Flex
						justifyContent={"center"}
						my={5}
						w="100%"
						bg="#fff"
						bgClip={"padding-box"}
						border="3px solid transparent"
						borderRadius={"5px"}
						position="relative"
						maxW={["80%", "400px"]}
						_before={{
							content: "''",
							position: "absolute",
							top: "0",
							right: "0",
							bottom: "0",
							left: "0",
							zIndex: "-1",
							margin: "-3px",
							borderRadius: "inherit",
							bg: "linear-gradient(to right, #9E19AB, #7123B7)",
						}}
					>
						<Button
							fontSize={["18px", "22px"]}
							color="#000"
							bg="#fff"
							w="100%"
							maxW="400px"
							fontWeight={"900"}
							borderRadius="none"
							_hover={{ bg: "#fff" }}
							_active={{ bg: "#fff" }}
							onClick={submit}
						>
							JOIN NOW
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};

export default BetaAccess;
