import { Box, Container, Flex } from "@chakra-ui/react";
import Card from "./Card/Card";
import fireEmoji from "../../../assets/img/fire-emoji.png";
import rocketEmoji from "../../../assets/img/rocket-emoji.png";

const Jobs = () => {
	const hottestProjects = [
		{ name: "Marketing Lead for new Defi Exchange" },
		{ name: "Scale Application Across Multiple Chains" },
		{ name: "React Developer for Web and Mobile Applications" },
		{ name: "Analytics for an NFT Game" },
		{ name: "Manage and Run NFT Drop" },
	];
	const latestProjects = [
		{ name: "Marketing Lead for new Defi Exchange" },
		{ name: "Scale Application Across Multiple Chains" },
		{ name: "React Developer for Web and Mobile Applications" },
		{ name: "Analytics for an NFT Game" },
		{ name: "Manage and Run NFT Drop" },
	];
	return (
		<>
			<Container maxW={["100%", "95%"]}>
				<Flex
					flexDirection={["column", null, "row"]}
					gridColumnGap="2.5rem"
					gridRowGap={8}
				>
					<Card
						data={hottestProjects}
						cardHeading="HOTTEST JOBS"
						image={fireEmoji}
					/>

					<Card
						data={latestProjects}
						cardHeading="LATEST JOBS"
						image={rocketEmoji}
					/>
				</Flex>
			</Container>
		</>
	);
};

export default Jobs;
