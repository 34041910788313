import { Box, Heading, Text, Button, Center } from "@chakra-ui/react";
import banner from "../../../assets/img/banner.webp";
import mobileBanner from "../../../assets/img/mobile-banner.webp";

const HomeBanner = () => {
	return (
		<>
			<Center
				as="section"
				flexDirection={"column"}
				textAlign="center"
				bgImage={[mobileBanner, banner]}
				bgPosition="center"
				bgSize={"cover"}
				bgRepeat="no-repeat"
				minH={["500px", "550px"]}
				px={2}
				position="relative"
			>
				<Box>
					<Heading
						fontWeight={"900"}
						color="#fff"
						fontSize={["2rem", "3rem", "3.5rem"]}
					>
						HIRING FOR WEB3
					</Heading>
					<Text
						color="#fff"
						fontWeight={"500"}
						fontSize={["0.8rem", "1.35rem"]}
					>
						Hire. Get Paid Instantly. Backed by NFT's.
					</Text>
				</Box>
				<Button
					display={["inline-block", "none"]}
					bgGradient={"linear(to-r, #9E19AB, #7123B7)"}
					_hover={{ bgGradient: "linear(to-l, #9E19AB, #7123B7)" }}
					_active={{ bgGradient: "linear(to-r, #9E19AB, #7123B7)" }}
					fontWeight="900"
					fontSize={"1rem"}
					size={"sm"}
					borderRadius="3px"
					position={"absolute"}
					bottom={"3rem"}
					color="#fff"
				>
					COMING SOON
				</Button>
				<Button
					display={["none", "inline-block"]}
					bgGradient={"linear(to-r, #9E19AB, #7123B7)"}
					_hover={{ bgGradient: "linear(to-l, #9E19AB, #7123B7)" }}
					_active={{ bgGradient: "linear(to-r, #9E19AB, #7123B7)" }}
					fontWeight="900"
					fontSize={"1.2rem"}
					borderRadius="3px"
					position={"absolute"}
					bottom={"6.5rem"}
					color="#fff"
				>
					COMING SOON
				</Button>
			</Center>
		</>
	);
};

export default HomeBanner;
