import React from "react";
import logo from "../../assets/img/pyme-logo-new.png";
// import MobileDrawer from "../Mobile Drawer/MobileDrawer";
import TwitterCircle from "../../assets/svg/TwitterCircle";
import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import MailIcon from "../../assets/svg/MailIcon";

export default function Simple() {
	return (
		<Box
			px={[5, null, 10]}
			py={2}
			boxShadow={"md"}
			as={"header"}
			pos={"fixed"}
			top={"0"}
			w="100%"
			zIndex={"999"}
			bg={"#fff"}
		>
			<Flex alignItems={"center"} justifyContent={"space-between"}>
				<Link as={NavLink} to="/">
					<Image src={logo} />
				</Link>

				<Flex>
					<Flex alignItems={"center"} gap={["1rem", "2.5rem"]}>
						<Link
							display={["none", "block"]}
							target={"_top"}
							fontFamily={"Poppins"}
							fontWeight="600"
							href="mailto:start@pyme.team"
						>
							Contact us
						</Link>
						<Link href="mailto:start@pyme.team" fill="#7123B7">
							<MailIcon width="25px" height="25px" />
						</Link>
						<Link isExternal href="https://twitter.com/Pyme_team">
							<TwitterCircle width="40px" height="40px" />
						</Link>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
}
